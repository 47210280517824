@import '../../utilities/breakpoints';
@import '../../utilities/mixins';

@keyframes fade-in {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}



//Hero

.hero {
  width: 100%;
  background:
    linear-gradient(255deg, rgba(0, 0, 0, 0) 3.77%, rgba(0, 0, 0, 0.76) 48.14%, #000 100%),
    url('../../../img/home/hero\ banner.jpg') lightgray 50% / cover no-repeat;
  background-position: right 20% top;
  border-radius: 0rem 0rem 0rem 10rem;
  color: var(--color-white);

  @media only screen and (max-width: $bp-600) {
    border-radius: 0rem 0rem 0rem 8rem;
  }
  .container {
    @include container();
    min-height: 100dvh;
    justify-content: center;
    align-items: start;
    gap: 2.4rem;
    opacity: 0;
    animation: fadeInSlideUp 1s ease-in-out forwards;
    @media only screen and (max-width: $bp-600) {
      min-height: 80dvh;
      text-align: center;
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      width: 80%;
      font-family: var(--secondary-font);
      text-transform: capitalize;
      @media only screen and (max-width: $bp-600) {
        width: 100%;
        align-items: center;
      }
      span {
        color: var(--color-primary-3);
        font-weight: 600;
        display: inline-block; // Make sure it's block to animate correctly
      }
    }

    .buttonSet {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      gap: 2rem;
      @media only screen and (max-width: $bp-600) {
        align-self: center;
      }
      @media only screen and (max-width: $bp-500) {
        flex-direction: column;

      }
    }
  }
}

.dl {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  transition: all 0.3s;
  animation: fade-in linear;
  animation-timeline: view(90% 0%);
  height: 4rem;
  .dlButton{
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: .8rem 1.5rem;
    border-radius: 1rem;
    background-color: var(--color-white);
    color: var(--color-dark);
    transition: all 200ms ease-in-out;
    &:hover{
      transform: scale(1.05);
      background-color: var(--color-dark);
      color: var(--color-white);
    }

    >.textArea{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  
    }
  }

  img {
    transition: width 200ms ease-in-out;

    &:hover {
      width: 130px;
      transition: width 200ms ease-in-out;
    }
  }
}

//HowTo
.howTo {
  width: 100%;
  background: var(--color-white-2);

  .container {
    @include container();
    gap: 3.2rem;
    .titleText {
      font-family: var(--secondary-font);
      span {
        color: var(--color-primary-2);
      }
    }
    .set {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      align-self: stretch;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: $bp-800) {
        flex-direction: column;
      }
      .setImage{
        opacity: 0;
        transform:scale(.9);
        transition: all 100ms ease-in-out;
        &.fadeIn{
          opacity: 1;
          transform:scale(1);
          transition: all 300ms ease-in-out;
        }
      }
      .stepsCard {
        display: flex;
        flex: 1 0 0;
        min-width: 59.3rem;
        max-width: 60rem;
        padding: 4rem 6rem;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 4rem;
        background: var(--color-white);
        border-radius: 2rem;

        @media only screen and (max-width: $bp-800) {
          min-width: unset;
        }
        @media only screen and (max-width: $bp-600) {
          min-width: unset;
          padding: 3.6rem 1.3rem;
        }
        .stepsCardTitle {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-self: stretch;
          gap: 1.2rem;

          @media only screen and (max-width: $bp-600) {
            align-items: center;
          }
        }
        .stepsList {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .step,
          .lastStep {
            display: flex;
            flex-direction: column;

            .title {
              display: flex;
              align-items: center;
              gap: 1.6rem;

              .icon {
                width: 1.6rem;
                height: 1.6rem;
                background-color: var(--color-dark);
                border-radius: 50%;
              }
            }

            .details {
              display: flex;
              gap: 1.6rem;

              .line {
                min-width: 1.6rem;
              }

              hr {
                margin: 0rem auto;
                width: 0.1rem;
                height: 100%;
                border: 1px dashed black;
              }
            }
          }

          .lastStep {
            .details hr {
              display: none; /* Hide the hr for the last step */
            }
          }
        }
        .buttonSet {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 2rem;
          @media only screen and (max-width: $bp-600) {
            :nth-child(2) {
              display: none;
            }
          }
        }
      }
    }
  }
}

//Benefits
.benefit {
  width: 100%;
  background: var(--color-dark);
  .container {
    @include container();
    gap: 14.2rem;
    @media only screen and (max-width: $bp-600) {
      gap: 4rem;
    }
    .title {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      font-family: var(--secondary-font);
      gap: 2rem;
      @media only screen and (max-width: $bp-800) {
        flex-direction: column;
      }
      span {
        color: var(--color-primary);
        font-weight: 800;
      }
      .titleText {
        flex-basis: 60%;
        flex-grow: 1;
      }
    }
    .benefitList {
      display: flex;
      align-self: stretch;
      flex-direction: column;
      gap: 4.2rem;
      @media only screen and (max-width: $bp-600) {
        gap: 3rem;
      }
      .card {
        display: flex;
        padding: 6.4rem 2.8rem;
        justify-content: space-between;
        gap: 8.2rem;
        align-self: stretch;
        border-radius: 2rem;
        background: var(--color-white);
        box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.15);
        animation: fade-in 1s ease-in-out;
        animation-timeline: view(90% 0%);
        transform: scale(0.8);
        opacity: 0;
        transition: all 400ms ease-in-out;
        &.fadeIn {
          transform: scale(1);
          opacity: 1;
          transition: all 400ms ease-in-out;
        }
        @media only screen and (max-width: $bp-800) {
          align-items: center;
          flex-direction: column;
          gap: 1rem;
          padding: 4rem 2rem;
        }
        .textArea {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 2rem;
          max-width: 75.5rem;
          @media only screen and (max-width: $bp-800) {
            max-width: 100%;
            text-align: center;
            gap: 1.2rem;
            align-items: center;
          }
          .subtitle {
            max-width: 57rem;
            @media only screen and (max-width: $bp-800) {
              max-width: 100%;
            }
          }
        }
        span {
          color: #7a5709;
          font-weight: 700;
        }
      }
      img {
        object-fit: contain;
      }
    }
  }
}

//Testimonials
.testimonials {
  background: var(--color-white-2);
  .container {
    @include container();
    gap: 3.2rem;
    overflow: hidden;
    @media only screen and (max-width: $bp-600) {
      gap: 2.4rem;
    }
    .title {
      display: flex;
      max-width: 102.7rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.6rem;
      text-align: center;
      font-family: var(--secondary-font);
    }
    .embla {
      overflow: hidden;

      &__container {
        display: flex;
      }
      &__slide {
        flex: 0 0 37.5rem;
        min-width: 0;
        height: 26rem;
        display: flex;
        padding: 3.5rem 2.3rem 3.7rem 2.2rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 2.2rem;
        border-radius: 2rem;
        border: 1px solid var(--color-grey-2);
        background: var(--color-white);
        margin: 0 2.3rem;
        user-select: none;
        .testimony {
          font-size: 1.8rem;
          line-height: 2.7rem;
          font-weight: 400;
          color: var(--color-dark);
          display: -webkit-box; /* Use box model for truncation */
          -webkit-line-clamp: 4; /* Number of lines to display */
          line-clamp: 4;
          -webkit-box-orient: vertical; /* Box orientation */
          overflow: hidden; /* Hide overflowing content */
          text-overflow: ellipsis; /* Apply ellipsis at the end of the content */
          height: calc(4lh);
        }
        .user {
          display: flex;
          align-items: center;
          gap: 1.6rem;
          img {
            border-radius: 100%;
          }
          h5 {
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.9rem;
            margin: 0;
          }
        }
      }
    }

    .indicators {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }

    .indicator {
      width: 10px;
      height: 10px;
      background-color: var(--color-primary-5);
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;
      border: none;
      outline: none;
      transition: width 500ms ease-in-out;

      &.isActive {
        background-color: var(--color-primary-2);
        width: 20px;
        border-radius: 30%;
        transition: width 500ms ease-in-out;
      }
    }
  }
}

//Pricing
.pricing {
  width: 100%;
  .container {
    @include container();
    gap: 3.2rem;
    @media only screen and (max-width: $bp-600) {
      gap: 3.2rem;
    }
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.8rem;
      font-family: var(--secondary-font);
    }

    .billingSelector {
      display: grid;
      grid-template-columns: repeat(4, max-content);
      gap: 4rem;
      border-radius: 0.4rem;
      border: 2px solid var(--color-grey-2);
      padding: 1rem;
      width: fit-content;
      font-weight: 500;

      @media only screen and (max-width: $bp-700) {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        width: 100%;
      }

      .billingOption {
        transition: all 0.4s;
        padding: 1rem 1.2rem;
        cursor: pointer;
        border: none;
        border-radius: 0.4rem;
        font-size: 1.8rem;

        &.selected {
          background-color: var(--color-dark);
          color: var(--color-white);
          transition:
            background-color 0.3s ease-in 200ms,
            color 0.3s ease-in 200ms;
        }
      }
    }
    .pricingCardList {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 6.4px;
      align-self: stretch;
      justify-content: center;

      .pricingCard {
        display: flex;
        flex-direction: column;
        flex: 0 0 36rem;
        border-radius: 0.4rem;
        border: 1px solid var(--color-grey-2);
        .top {
          padding: 2.8rem 3.2rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 2.4rem;
          align-self: stretch;
          font-family: var(--tertiary-font);
          .textArea {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            align-self: stretch;
            .prominent {
              display: flex;
              align-items: flex-start;
              align-self: stretch;
              .price {
                font-size: 5.2rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.025rem;
                line-height: 5rem;
              }
              .priceSubtext {
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.025rem;
              }
            }
            .subtext {
              font-family: var(--primary-font);

              &.fade-out {
                opacity: 0;
                // transition: opacity 1ms ease;
              }

              &.fade-in {
                opacity: 1;
                transition: opacity 400ms ease 500ms;
              }
              span {
                color: var(--color-green);
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
            }
          }
        }
        hr {
          color: var(--color-grey-2);
        }
        .featureList {
          display: flex;
          flex-direction: column;
          align-self: stretch;
          gap: 1.2rem;
          padding: 2.8rem 3.2rem;
          height: 45rem;
          @media only screen and (max-width: $bp-600) {
            height: auto;
          }
        }
        &.recommended {
          background-color: var(--color-dark);
          color: var(--color-white);
          .top {
            .textArea {
              .pricingPlan {
                color: var(--color-primary);
              }
              span {
                color: var(--color-green-2);
              }
            }
          }
        }
      }
    }
  }
}

.dl2 {
  // margin: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  box-sizing: border-box;

  @media only screen and (max-width: $bp-800) {
    flex-direction: column;
  }
}

//Closer
.closer {
  background: var(--color-white-2);
  .container {
    @include container();
    gap: 3.2rem;
    @media only screen and (max-width: $bp-600) {
      padding: 4rem 2rem;
    }
    .frame {
      width: 100%;
      display: flex;
      padding: 8.6rem 4.1rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      border-radius: 2rem;
      background: linear-gradient(90deg, #514718 0%, #131000 100%);
      position: relative;
      opacity: 0;
      transform:scale(.9);
      transition: all 400ms ease-in-out;
      color: var(--color-white);
      @media only screen and (max-width: $bp-900) {
        align-items: center;
        padding: 5.8rem 2.8rem;
        overflow: hidden;
      }
      &.fadeIn {
        opacity: 1;
        transform:scale(1);
        transition: all 400ms ease-in-out;
      }
      >.textArea {
        display: flex;
        width: 70%;
        flex-direction: column;
        align-items: flex-start;
        gap: 3rem;
        .title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1.6rem;
          align-items: flex-start;
          @media only screen and (max-width: $bp-900) {
            text-align: center;
          }
        }
        @media only screen and (max-width: $bp-900) {
          width: 100%;
          align-items: center;
        }
      }
      .overflow {
        position: absolute;
        right: 3.3676rem;
        bottom: 0rem;
        @media only screen and (max-width: $bp-900) {
          position: static;
          margin-bottom: -6rem;
        }
      }
    }
  }
}
