@mixin container(
  $padding-large: 10rem 11rem, 
  $padding-small: 4rem 2.5rem, 
  $flex-direction-large: column
) {
  width: 100%;
  max-width: 144rem;
  margin: 0 auto;
  padding: $padding-large;
  display: flex;
  flex-direction: $flex-direction-large;
  align-items: center;

  @media only screen and (max-width: $bp-800) {
    padding: $padding-small;
  }

  @media only screen and (max-width: $bp-600) {
    flex-direction: column;
  }
}
